// InteractiveVideoOverlay.js

import React, { useState,useEffect,useRef } from 'react';
import ReactPlayer from 'react-player';

const InteractiveVideoOverlay = () => {
  const playerRef = useRef(null);
  const [playVideo,setplayVideo]=useState(false)
  const data=[{
    id:1,
    videoUrl:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
    question:[{time:2,text:"What is your favorite color?",answer:[
      {text:"Blue",sagment:2},
      {text:"Black",sagment:3}]}],
        
  },{
    id:2,
    videoUrl:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
    question:[{time:4,text:"What is your favorite dev?",answer:[
      {text:"Node",sagment:1},
      {text:"React",sagment:3}]  }],
         
  },{
    id:3,
    videoUrl:"http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4",
    question:[{time:5,text:"What is your favorite food?", answer:[
      {text:"Mengo",sagment:1},
      {text:"Greps",sagment:2}]  }],
      
  }]
  const handleClick = () => {
    setplayVideo(!playVideo);

    if (playerRef.current) {
      if (playVideo) {
        playerRef.current.player.player.pause();
      } else {
        playerRef.current.player.player.play();
      }
    }
    
  };

  
  const [currentVideoObj,setCurrentVideoObj]=useState(data[0])

  const [currentQuestion, setCurrentQuestion] = useState(null);
  
  const handleProgress = (state) => {
    const currentTime = state.playedSeconds;

    // Find the question that corresponds to the current time
    const questionToShow = currentVideoObj.question.find(
      (question) => currentTime >= question.time && currentTime < question.time + 5
    );
    
    if (questionToShow && !currentQuestion) {
      // Display the question if it's the first time we encounter it
      setCurrentQuestion(questionToShow);
      setplayVideo(false)
    } else if (!questionToShow && currentQuestion) {
      // Reset currentQuestion if no question is found
      setCurrentQuestion(null);
    }
  };

  const handleAnswer = (answer) => {
    const devData = data.find(
      (question) => question.id==answer
    );
      setCurrentQuestion(null);
      setplayVideo(true)
    setCurrentVideoObj(devData)
   
   
  };

  return (
    <div className="interactive-video-container"  onClick={handleClick}>
      <ReactPlayer
        url={currentVideoObj.videoUrl}
        controls={false}
        ref={playerRef}
        onProgress={handleProgress}
        playing={playVideo}
        width="100%"
        height="auto"
      />
      {currentQuestion && (
        <div className="overlay">
          <p style={{color:"black",marginBottom:"0px"}}>{currentQuestion.text}</p>
         <div style={{display:"flex",justifyContent:"center"}}> {
            currentQuestion.answer?.map((i)=>{
              return( <div style={{marginLeft:"5px"}}><button onClick={() => handleAnswer(i.sagment)}>{i.text}</button></div>)
            })
          }
         </div>
        </div>
      )}
    </div>
  );
};

export default InteractiveVideoOverlay;
